@import "../../styles/components";

.standSelectorButton {
  @include dropdown-btn;

  width: 160px;
  justify-content: space-between;
}

$bk-color: #445059;
$item-height: 28px;
$column-width: 120px;

.standSelectorPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto Mono;
}

.triangle {
  $size: 10px;

  border: $size solid transparent;
  border-bottom-color: $bk-color;
  margin-top: -$size;
}

.inner {
  background: $bk-color;
  display: flex;
  border-radius: 2px;
  margin-left: 35px;
}

.groups {
  background: #7d909d;
  width: $column-width;
  padding-top: 4px;
}

.groupBtn {
  display: flex;
  height: $item-height;
  align-items: center;
  padding-left: 20px;
  padding-right: 13px;
  border-bottom: 1px solid #9eabb5;

  &.active {
    background: rgba(white, 0.1);
  }

  i {
    margin-left: auto;
  }
}

.stands {
  display: flex;
  min-width: $column-width;
}

.standsColumn {
  padding-top: 4px;
  width: $column-width;
}

.standsColumn + .standsColumn {
  border-left: 1px solid #9eabb5;
}

.standBtn {
  display: flex;
  height: $item-height;
  padding-left: 12px;
  align-items: center;
  break-inside: avoid;
  height: 28px;

  &:hover,
  &.active {
    background: rgba(white, 0.1);
  }

  img {
    height: 16px;

    &:first-child {
      margin-right: 6px;
    }

    &:not(:first-child) {
      height: 14px;
    }
  }

  span {
    margin-right: 6px;
  }
}
