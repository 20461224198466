@import "../../styles/mixins";

.gate-popup {
  position: fixed;
  background: white;
  padding: 20px;
  color: #4D4F51;
  font-weight: 500;
  font-size: 12px;

  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto;
  -ms-grid-rows: 30px auto auto;
  -ms-grid-columns: auto auto auto;

  $triangle-size: 8px;

  &::after {
    content: '';
    border: $triangle-size solid transparent;
    position: absolute;
  }

  &_placement_bottom {
    transform: translate(-50%, $triangle-size + 2px);

    &::after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: white;
    }
  }

  &_placement_top {
    transform: translate(-50%, -$triangle-size - 2px);

    &::after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: white;
    }
  }

  &__header {
    grid-column-start: 1;
    grid-column-end: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  &__stand {
    background: #353E47;
    border-radius: 2px;
    line-height: 20px;
    padding: 0px 4px;
    margin-right: 8px;
    color: white;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
  }

  &__inbound-flight {
    @include grid-cords(2,1);
    font-weight: 500;
    font-size: 20px;
  }

  &__outbound-flight {
    @include grid-cords(2,3);
    font-weight: 500;
    font-size: 20px;
  }

  &__divider {
    align-self: center;
    font-size: 8px;
    margin-left: 8px;
    margin-right: 8px;
    @include grid-cords(2,2);
  }

  &__airport-from {
    @include grid-cords(3,1);
    i {
      color: #4F5C66;
      font-size: 80%;
      margin-left: 0.5em;
    }
  }

  &__airport-to {
    @include grid-cords(3,3);
    i {
      color: #4F5C66;
      font-size: 80%;
      margin-right: 0.5em;
    }
  }
}