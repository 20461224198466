@import "../../styles/constants";

.video-player {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__white-noise {
    background-size: contain;
    height: 100%;
    width: 100%;
  }

  &__media-container {
    position: relative;
    display: table;
  }

  &__video-preview {
  }

  &__video {
    &_invisible {
      display: none;
    }
  }
}