@import "../../styles/constants";

.support-button-container {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  pointer-events: none;

  .support-button {
    display: flex;
    height: 44px;
    min-height: 44px;
    width: 44px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 12px;
    z-index: 9999999;
    pointer-events: auto;

    border-radius: 50%;
    background: #4F5C66;
    border: 1px solid #828F99;
    opacity: 1;

    & > img {
      width: 26px;
    }
  }
}