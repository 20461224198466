@import "../../styles/constants";

.sidebarContent {
  overflow: auto;
  overflow-y: scroll;
  margin-bottom: 1px;
  position: relative;
  padding-left: 2px;
}

.groupHeader {
  min-height: 24px;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 10px;
  user-select: none;
  background: #353E47;
  padding-left: $sidebar-padding-h;
  padding-right: $sidebar-padding-h/2;
  align-items: center;
  color: #A9B1B8;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2px;
  cursor: pointer;
  transition: 0.2s;

  &.hoverable {
    &:hover {
      background: #3e4347;
    }
  }
}

.groupBadge {
  padding: 2px;
  color: #353E47;
  display: inline-block;
  background: #A9B1B8;
  border-radius: 2px;
  justify-self: start;

  &.current {
    background: #BD4E2B;
    color: white;
  }
}

.groupIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  color: #A9B1B8;
  font-size: 16px;
}

.spinner {
  display: table;
  position: static;
  margin: auto;
}

.loadMoreBtn {
  display: block;
  text-align: center;
  margin-top: 4px;
  font-size: 16px;
  text-decoration: underline;
  padding: 12px 0px;
}

.noAlertsMsg {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  padding: 1rem 0px;
  color: #454d53;
}

.markAll {
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto auto;
  gap: 6px;
  border-radius: 2px;
  padding: 2px 4px;
  transition: 0.2s;
  color: white;
  margin: 4px 0px;

  &.hidden {
    visibility: hidden;
  }

  .icon {
    color: #A9B1B8;
    transition: inherit;
  }

  &:hover {
    background: rgba(226, 226, 226, 0.15);

    .icon {
      color: #43AA33;
    }
  }
}

.transitionEnter {
  opacity: 0;
  transform: scale(0.9);
}

.transitionEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.transitionExit {
  opacity: 1;
}

.transitionExitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
