@import "../../styles/constants";
@import "../../styles/components";

.main-menu {
  background: $bk-dark;

  display: flex;
  align-items: center;
  padding-left: 27px;
  padding-right: 25px;
  overflow: auto;
  position: relative;
  width: 100%;

  & > * {
    flex-shrink: 0;
  }

  &__map-btn {
    @include btn;
    width: 120px;

    img {
      height: 18px;
      margin-right: 8px;
    }
  }

  &__slash {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 0px 14px;
    color: #A9B1B8;
  }

  &__turn-selector {
    margin-right: 30px;
  }

  &__export-btn {
    margin-left: auto;
    margin-right: 0px;
  }
}