$bk-mid: #1b1e24;
$bk-dark: #161619;
$bk-light: #2A3339;
$bk-darkest: #0E0E10;

$bk-grey: #2a3135;
$grey-light: #efefef;
$grey-medium: #9b9b9b;
$grey-dark: #57595b;
$red: #ef1126;
$black-dark: rgb(28, 33, 37);
$icon-color: #A9B1B8;

$header-height: 60px;
$toolbar-height: 55px;
$grid-gap: 2px;
$sidebar-padding-h:22px;
$border-radius: 3px;
$desktop-padding: 28px;

$video-cell-width: 320px;
$video-cell-height: 160px;
$video-toolbar-height: 32px;

$modal-width: 500px;
$frame-ratio:2;

$app-grid-rows: 60px 55px 1fr 51px;