@import "../../styles/constants";

$progress-bar-height: 1rem;

.progressBarContainer {
  position: relative;
  width: 100%;
  height: $progress-bar-height;
  overflow: visible;
}

.progressBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $progress-bar-height;
  background: #a2a2a3;
  transition: height 0.15s;
  z-index: 1;
  overflow: visible;
  cursor: pointer;
}

.timeTooltip {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.66rem;
  width: 5.33rem;
  transform: translateY(-2rem);
  left: 20px;
  z-index: 1;
  background: #161619b2;
  color: white;
  user-select: none;

  font-family: Roboto Mono;
  font-size: 1rem;
  border-radius: 2px;
}

.slider {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 0.5rem;
  background: #ed1222;
  z-index: 1;
}

.event {
  position: absolute;
  pointer-events: none;
  height: 100%;
  background: #dadada;
}

.flipped {
  transform: scaleX(-1);
}

.hoverable {
  transition: 0.1s;

  &:hover {
    opacity: 0.7;
  }
}

.dateTime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  user-select: none;

  .date {
    font-family: Roboto Mono;
    font-weight: bold;
    font-size: 10px;
    color: #828f99;
    margin-bottom: 3px;
  }

  .time {
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 14px;
  }
}

.frame {
  height: $modal-width/2;
}

.frameUnavailableWrapper {
  height: 250px;
  position: relative;
}