@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../../styles/mixins";

.home {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 11px 1fr;
  grid-template-rows: auto $app-grid-rows;
  display: -ms-grid;
  -ms-grid-columns: auto 11px 1fr;
  -ms-grid-rows: auto $app-grid-rows;

  &_sidebar-disabled {
    grid-template-columns: auto 0 1fr;
  }

  &.no-user-select {
    user-select: none;
  }

  &__warning {
    background: #ff4f38;
    text-shadow: black 0px 0px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 30px;
    //color: #2a3135;
    color: white;
    text-align: center;
    white-space: nowrap;
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;

    grid-row: 1;
    grid-column: 1/4;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;

    p + p {
      margin-top: -10px;
    }
  }

  &__header {
    z-index: 1;

    @include grid-cords(2,3);
  }

  &__main-menu {
    @include grid-cords(3,3);
  }

  &__sidebar {
    grid-column: 1;
    grid-row: 2/6;
  }

  &__resizer {
    grid-column: 2;
    grid-row-start: 2;
    grid-row-end: 6;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-row-span: 4;
  }

  &__inner {
    grid-column: 3;
    grid-row-start: 4;
    grid-row-end: 6;
    -ms-grid-column: 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 2;
  }

  &__error {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column: 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
  }

  &__footer-logo {
    position: fixed;
    left: 26px;
    bottom: 16px;
    opacity: 0.7;
    z-index: 100;

    svg {
      height: 20px;
      margin-right: 15px;
      vertical-align: middle;
    }

    span {
      font-size: 10px;
      color: #8C8C8D;
      vertical-align: middle;
      position: relative;
      top: 3px;
    }
  }

  &_win-os {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px #ddd;
      border: solid 3px transparent;
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px #666;
      border: solid 3px transparent;
    }

    -ms-overflow-style: -ms-autohiding-scrollbar;

    scrollbar-color: $grey-medium $bk-grey;
    scrollbar-width: thin;
  }
}

@include mobile {
  .home {
    grid-template-rows: auto 40px auto 1fr 51px;
    -ms-grid-rows: 40px auto 1fr 51px;

    &__footer-logo {
      display: none;
    }
  }
}