.frame {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;

  .img-container {
    position: relative;
  }

  img {
    display: block;
  }

  .white-noise {
    background-size: contain;
    height: 100%;
    width: 100%;
  }
}