@import "../../styles/constants";
@import "../../styles/breakpoints";

$modal-padding-h: 1.66rem;
$modal-padding-section: 0.75rem;
$modal-section-title-bk:  #E0E0E0;

.modal-container {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  pointer-events: none;
  color: $bk-dark;

  &.backdrop {
    pointer-events: all;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal {
    pointer-events: all;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 4px;
    background: white;
    width: $modal-width;
    max-width: calc(100vw - 20px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: translate(-50%, -50%);

    &.resizable {
      border-radius: 4px 4px 0px 4px;
    }

    &.resizable,
    &.movable {
      transform: none;
    }

    @media (max-height: 800px) {
      max-height: calc(100vh - 10px);
    }

    @media (min-height: 801px) {
      max-height: 90vh;
    }

    .modal-header {
      padding: 22px $modal-padding-h;
      display: flex;
      font-weight: 500;
      font-size: 20px;
      align-items: center;

      &.movable {
        cursor: grab;

        &.grabbing {
          cursor: grabbing;
        }
      }

      .badge {
        display: inline-block;
        background: #BD4E2B;
        border-radius: 2px;
        line-height: 20px;
        padding: 0px 4px;
        margin-left: 8px;
        font-weight: 500;
        font-size: 12px;
        color: #E2E2E2;
      }

      .close-btn {
        margin-left: auto;
        cursor: pointer;
        width: 40px;
        line-height: 40px;
        text-align: center;

        &:hover {
          background: rgba(196, 196, 196, 0.397);
          border-radius: 2px;
        }
      }
    }

    .resize-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 25px;
      width: 25px;
      pointer-events: none;
      z-index: 100;

      .icon {
        position: absolute;
        pointer-events: all;
        cursor: nwse-resize;
        font-size: 14px;
        bottom: -2px;
        right: 2px;
        transform: rotate(45deg);
        color: white;
        opacity: 0.25;
        transition: 0.2s;

        &:hover {
          opacity: 0.4;
        }
      }
    }

    .modal-section-title {
      font-weight: 600;
      font-size: 1rem;
      color: #4D4F51;
      padding-left: $modal-padding-h;
      padding-right: $modal-padding-h;
      padding-top: $modal-padding-section;
      padding-bottom:$modal-padding-section;
      text-transform: uppercase;
      background: $modal-section-title-bk;
      display: flex;
      align-items: center;

      .modal-section-title_badge {
        margin-left: auto;
        font-weight: 500;
      }
    }

    .list {
      user-select: none;
    }

    .list.scrollable {
      flex: 1;
      overflow: auto;
    }

    .list-item {
      padding: 1rem $modal-padding-h;
      font-size: 16px;
      display: block;

      i {
        margin-right: 9px;
        position: relative;
        top: 1px;
        color: #A9B1B8;
      }

      &.active {
        font-weight: 500;
        background: #EEEFF1;

        i {
          color: #2F373D;
        }
      }

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}

.ie11 .modal .list.scrollable {
  flex: auto !important;
  max-height: 150px;
}
