@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../../styles/mixins";

.zoom-container {
  position: relative;

  &__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  &__modal {
    position: relative;
    min-height: 360px;
    min-width: 480px;
    height: 360px;
    width: 480px;
  }

  &__wrapper>*:not(&__button) {
    height: 100%;
    width: 100%;
  }

  &_zoomed {
    height: 100%;
    width: 100%;
  }

  &__button {
    position: absolute;
    right: .5rem;
    top: .5rem;
    color: white;
    z-index: 1;
    transition: 0.2s;
    background: rgba(black,.5);
    display: block;
    padding: 5px;
    border-radius: 2px;

    &:hover {
      color: #F8F0DF;
    }
  }
}