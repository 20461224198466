@import "../../styles/constants";
// @import "../../styles/components";

$severity-low: #a9b1b8;
$severity-medium: #ee9d4c;
$severity-high: #d34749;

$title-color: rgba(226, 226, 226, 0.15);

$alert-btn-width: 32px;
$alert-btn-hover-width: 50px;

.alertWrapper {
  display: flex;
  margin-bottom: 2px;
  position: relative;
}

.alertButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  background: #4d4f51;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s;

  &.hidden {
    display: none;
  }

  &:hover {
    background: #464849;
  }

  &.low {
    background: rgba($severity-low, 0.8);
    &:hover {
      background: rgba($severity-low, 0.9);
    }
  }

  &.medium {
    background: rgba($severity-medium, 0.8);
    &:hover {
      background: rgba($severity-medium, 0.9);
    }
  }

  &.high {
    background: rgba($severity-high, 0.8);
    &:hover {
      background: rgba($severity-high, 0.9);
    }
  }
}

.alert {
  flex: 1;
  background: #161619;
  border-left: 4px solid #49535d;
  padding: 20px 16px;
  cursor: pointer;

  &.low {
    border-left-color: $severity-low;
  }

  &.medium {
    border-left-color: $severity-medium;
    background: #3e372c;
  }

  &.high {
    border-left-color: $severity-high;
    background: #312124;
  }
}

.header {
  display: grid;
  grid-template-columns: auto 28px 1fr;
  align-items: center;
  height: 20px;
  margin-bottom: 16px;
}

.tooltipComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  height: 100%;
  width: 20px;
  transition: 0.2s;

  &:hover {
    background: $title-color;
    border-radius: 2px;
  }
}

.alertTooltip {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 10px;
  line-height: 16px;
  border-radius: 2px;
  padding: 4px 6px;
  max-width: 250px;
  $tooltip-color: rgba(0, 0, 0, 0.85);
  background: $tooltip-color;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  // Tooltip arrow
  &::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  span:nth-child(odd) {
    font-size: 11px;
    text-transform: uppercase;
    text-align: end;
    color: #a9b1b8;

    font-family: Roboto Mono;
    font-style: normal;
    font-weight: 500;
  }

  span:nth-child(even) {
    color: #ffffff;
    font-size: 12px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.title {
  background: $title-color;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding-right: 4px;
  margin-right: 12px;

  img {
    height: 20px;
    margin-right: 4px;
  }
}

.titleSeparator {
  font-size: 4px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0px 4px;
  position: relative;
  top: 1px;
}

.titleArrow {
  transform: rotate(45deg);
  margin-left: 8px;
  margin-right: 4px;
}

.time {
  font-weight: 500;
  font-size: 12px;
  color: #a9b1b8;
  align-self: center;
  margin-right: auto;
  margin-top: 16px;
}

.questionMark {
  justify-self: center;
}

.severity {
  background: rgba(187, 220, 251, 0.2);
  border-radius: 2px;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  width: fit-content;
  justify-self: end;
  height: 100%;

  &.low {
    background: rgba($severity-low, 0.8);
  }

  &.medium {
    background: rgba($severity-medium, 0.8);
  }

  &.high {
    background: rgba($severity-high, 0.8);
  }
}

.text {
  font-size: 14px;
  line-height: 1.3;

  &::first-letter {
    text-transform: uppercase;
  }
}
