@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../../styles/mixins";

.sidebar {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: $app-grid-rows;
  transition: width 0.1s ease-in-out;

  &.resizing {
    transition: none;
  }

  &.hidden {
    width: 0;
  }
}

.header {
  @include grid-cords(1, 1);
}

.menu {
  @include grid-cords(2, 1);
}

.alerts {
  @include grid-cords(3, 1);
}

.footer {
  @include grid-cords(4, 1);
}