$fa-font-path: "../../fonts/fontawesome/";
@import "fontawesome/fontawesome";

@import "fontawesome/solid";
@import "fontawesome/regular";
@import "fontawesome/light";

//100 Thin -
//200 Extra Light
//300 Light
//400 Regular
//500 Medium
//600 Semi Bold
//700 Bold -
//800 Extra Bold -
//900 Black -

/* roboto-mono-200 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-200.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-200.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-300 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-300.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-500 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-500.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-600 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-600.svg#RobotoMono') format('svg'); /* Legacy iOS */
}

/* work-sans-200 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/work-sans-v9-latin/work-sans-v9-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-200.woff') format('woff'), /* Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-200.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/work-sans-v9-latin/work-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/work-sans-v9-latin/work-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/work-sans-v9-latin/work-sans-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/work-sans-v9-latin/work-sans-v9-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/work-sans-v9-latin/work-sans-v9-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}