@import "../../styles/constants";

.firstShadow,
.secondShadow {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  height: 100%;
  width: 20px;
  background: red;
}

.firstShadow {
  &.horizontal {
    left: 0;
    background: linear-gradient(
      -90deg,
      rgba($bk-dark, 0) 0%,
      rgba($bk-dark, 1) 100%
    );
  }
}

.secondShadow {
  &.horizontal {
    right: 0;
    background: linear-gradient(
      90deg,
      rgba($bk-dark, 0) 0%,
      rgba($bk-dark, 1) 100%
    );
  }
}
