@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../../styles/mixins";

.turnaround-info-panel {
  display: grid;
  display: -ms-grid;
  text-transform: uppercase;
  padding-right: 25px;
  padding-left: 25px;
  font-weight: 500;
  font-size: 12px;
  color: #828F99;
  margin-bottom: 25px;
  font-family: Roboto Mono;
  min-height: $video-cell-height;

  grid-template-rows: 44px 24px 24px 24px 24px auto;
  grid-template-columns:  auto auto 1fr 40px minmax(90px, auto) 90px minmax(90px, auto) 50px $video-cell-width;
  align-items: self-end;

  &__aircraft-type {
    @include grid-cords(1,1);
  }

  &__time-status {
    @include grid-cords(2,7);
    justify-self: right;

    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    background: #353E47;
    border-radius: 2px;
    width: 60px;
    line-height: 20px;
    text-align: center;
    color: white;
  }

  &__inbound-flight {
    font-size: 24px;
    font-weight: 300;
    @include grid-cords(3,1);
  }

  &__airport-from {
    @include grid-cords(4,1);

    i {
      color: #4F5C66;
      font-size: 80%;
      margin-left: 0.5em;
    }
  }

  &__flight-divider {
    justify-self: center;
    font-size: 8px;
    margin-bottom: 7px;
    margin-left: 8px;
    margin-right: 8px;
    @include grid-cords(3,2);
  }

  &__outbound-flight {
    font-size: 24px;
    font-weight: 300;
    color: white;
    @include grid-cords(3,3);
  }

  &__airport-to {
    @include grid-cords(4,3);

    i {
      color: #4F5C66;
      font-size: 80%;
      margin-right: 0.5em;
    }
  }

  &__ibt-label {
    grid-column: 4;
    justify-self: right;
    text-transform: uppercase;
  }

  &__ibt-value {
    grid-column: 5;
    text-transform: uppercase;
  }

  &__ibt-value + &__obt-label {
    grid-row: 1;
  }

  &__ibt-value + &__obt-label + &__obt-value {
    grid-row: 1;
  }

  &__obt-label {
    justify-self: right;
    grid-column: 6;
    text-transform: uppercase;
  }

  &__obt-value {
    grid-column: 7;
    text-transform: uppercase;
  }

  &__obt-value, &__ibt-value {
    justify-self: right;
    text-align: right;
    grid-row: auto;

    &_big {
      margin-left: 1.5rem;
      font-weight: 300;
      font-size: 24px;
      color: white;
    }

    &:empty {
      font-size: inherit !important;

      &:before {
        content: '-';
      }
    }
  }

  &__video-cell {
    grid-column: 9;
    grid-row: 1/7;
    -ms-grid-column: 8;
    -ms-grid-row: 1/7;
    align-self: start;

    height: $video-cell-height;
  }

  &__alerts {
    @include grid-cords(7,9);
  }
}

.turnaround-info-panel_collapsed {
  grid-template-rows: 22px 22px 24px 24px 24px 24px 24px 48px;
  grid-template-columns:  1fr 60px minmax(90px, auto) 50px $video-cell-width;
  margin-top: 10px;
  margin-bottom: 25px;
  font-family: Roboto Mono;

  .turnaround-info-panel__aircraft-type {
    @include grid-cords(1,1);
  }

  .turnaround-info-panel__time-status {
    @include grid-cords(1,3);
  }

  .turnaround-info-panel__inbound-flight {
    @include grid-cords(3,1);
  }

  .turnaround-info-panel__airport-from {
    @include grid-cords(4,1);
  }

  .turnaround-info-panel__flight-divider {
    display: none;
  }

  .turnaround-info-panel__outbound-flight {
    @include grid-cords(6,1);
  }

  .turnaround-info-panel__airport-to {
    @include grid-cords(7,1);
  }

  .turnaround-info-panel__ibt-value + .turnaround-info-panel__obt-label {
    grid-row: auto;
  }

  .turnaround-info-panel__ibt-value + .turnaround-info-panel__obt-label + .turnaround-info-panel__obt-value {
    grid-row: auto;
  }

  &::before {
    content: "";
    grid-column: 2/4;
    grid-row: 3;
  }

  .turnaround-info-panel__ibt-label, .turnaround-info-panel__obt-label {
    grid-column: 2;
  }
  .turnaround-info-panel__ibt-value, .turnaround-info-panel__obt-value {
    grid-column: 3;
  }

  .turnaround-info-panel__video-cell {
    grid-column: 5;
    grid-row: 1/9;
  }

  .turnaround-info-panel__alerts {
    @include grid-cords(8,5);
  }
}