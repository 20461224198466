@import "../../styles/constants";

.sidebar-resizer {
  cursor: ew-resize;
  background: #353E47;
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__cursor {
    width: 1px;
    height: 12px;
    background: #A9B1B8;
  }
}