@import "../../styles/constants";
@import "../../styles/components";

.placeholder {
    font-size: 0.75rem;
}

// Override react-tooltip defaults
.turnInfoTooltip {
    @include btn-tooltip;
    text-transform: capitalize !important;
}