@import "../../styles/constants";
@import "../../styles/components";

$bk-color: #445059;

.turnaround-selector {
  display: inline-flex;
  align-items: stretch;

  &__prev-btn {
    @include btn;
    border-radius: $border-radius 0px 0px $border-radius;
    background: #4F5C66;

    &_disabled {
      opacity: .8;
      cursor: not-allowed;
    }
  }

  &__next-btn {
    @include btn;
    border-radius: 0px $border-radius $border-radius 0px;
    background: #4F5C66;

    &_disabled {
      opacity: .6;
      cursor: not-allowed;
    }
  }

  &__button {
    @include dropdown-btn;
    min-width: 420px;

    &_sharp-corners {
      border-radius: 0px;
    }
  }

  &__turn-icon {
    height: 20px;
    margin-right: 4px;
  }

  &__turn-label {
    margin-right: 12px;

    i {
      font-size: 4px;
      margin: 0px 6px;
      vertical-align: middle;
    }
  }

  &__turn-time {
    color: #A9B1B8;
  }

  &__popup {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__popup-triangle {
    $size: 10px;

    border: $size solid transparent;
    border-bottom-color: $bk-color;
    margin-top: -$size;
    margin-left: var(--popup-off-screen-x, 0);
  }

  &__popup-inner {
    background: $bk-color;
    display: flex;
    border-radius: 2px;
    overflow: hidden;
  }


  .dropdown-btn {
    border-radius: 0px;
    width: 550px;
    position: relative;
    
    .title {
      position: relative;
      z-index: 1;
    }

    .loading-bar {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  .prev,.next {
    vertical-align: middle;
    padding: 0px 6px;
    background: #4F5C66;
    width: 30px;
    min-width: 30px;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;

    &.disabled {
      background: rgba(79, 92, 102, 0.51);
      color: rgba(255,255,255,0.5);
      cursor: not-allowed;
    }

    i {
      position: relative;
      top:1px;
    }
  }

  .prev {
    border-radius: 3px 0px 0px 3px;
    border-right: none;
  }

  .next {
    border-radius: 0px 3px 3px 0px;
    border-left: none;
  }
}