@import "../../styles/constants";
@import "../../styles/breakpoints";

.fallback {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.4);
  z-index: 9999;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: white;
  overflow: hidden;
  border-radius: 3px;
  width: 400px;
}

.text {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 40px;
  color: black;
}

.buttons {
  display: flex;

}

.button {
  line-height: 54px;
  height: 54px;
  position: relative;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  color: white;
  background: rgba($bk-grey,.5);
  width: 50%;
}

.submit {
  background: #008582;
  color: white;
}