@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../Modal/style";

.modalContainer :global(.modal) {
}

.clear {
  font-weight: 500;
  font-size: 12px;
  color: #4D4F51;
  text-transform: capitalize;
  margin-left: auto;
}

.scrollContainer {
  overflow: auto;
  flex: 1;
  min-height: 150px;
  position: relative;
}

.spinner {
  background-color: black;
}

.items {
  display: grid;
  grid-template-columns: 50% 50%;
}

@mixin item-common {
  display: grid;
  grid-template-columns: 30px 68px 68px auto;
  padding: 0px 12px;
  align-items: center;
  font-family: Roboto Mono;
  font-weight: 500;
  text-transform: uppercase;

  &:nth-child(odd) {
    border-right: 1px solid rgba(#9EABB5,.2);
  }
}

.item {
  @include item-common;

  height: 53px;
  font-size: 14px;
}

.itemTitle {
  @include item-common;

  font-size: 9px;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.3);
}

.itemTitle + .item {
  grid-row-start: 2;
  border-right: 1px solid rgba(#9EABB5,.2);
}

.checkbox {
  margin-right: 15px;
}

.flightNumber {
  width: 90px;
}

.sobt {
  color: rgba(0, 0, 0, 0.5);
  justify-self: center;
}

.flightTitle {
  grid-column: 2;
}

.sobtTitle {
  grid-column: 3;
  justify-self: center;
}

.searchContainer {
  height: 40px;
  display: flex;
  padding: 0px $modal-padding-h;
  background: #E6E6E6;
  align-items: center;
  flex-shrink: 0;

  i {
    color: #777777;
    margin-right: 6px;
  }

  input {
    border: none;
    background: none;
    font-weight: 600;
    color: #4D4F51;
    outline: none;
  }
}

.saveBtn {
  line-height: 54px;
  height: 54px;
  position: relative;
  background: #008582;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  color: white;
  border-radius: 0px 0px 4px 4px;
}

.activeBtn {
  background: rgba(35, 144, 82, 0.3);
  border-radius: 2px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
}