@import "../../styles/mixins";

.empty-apron {
  padding-top: 25px;
  display: grid;
  grid-template-columns: 1fr 320px;
  grid-template-rows: 160px auto;

  h1 {
    font-weight: 300;
    font-size: 32px;
    color: #828f99;
    padding-top: 50px;
    font-family: Roboto Mono;
    flex: 1;
    margin-left: 25px;
    @include grid-cords(1,1);
  }

  .stand-video-stream,.white-noise {
    @include grid-cords(1,2);
    margin-right: 25px;
  }

  .timeline{
    opacity: 0.4;
    margin-top: 25px;
    align-self: start;
    @include grid-cords(2,1);
    grid-column: 1/3;
    -ms-grid-column: 1/3;
  }
}