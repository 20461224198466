@import "../../styles/constants";

.frame-unavailable {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.blackout {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.exception {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.msg {
  color: white;
  letter-spacing: 0.51px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  font-size: 16px;

  i {
    font-size: 24px;
    display: table;
    margin: auto;
    margin-bottom: 12px;
  }
}