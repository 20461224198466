@import "../../styles/constants";
@import "../../styles/breakpoints";

@mixin gate-text {
  font-family: "Roboto Mono";
  font-weight: 500;
  letter-spacing: -0.6px;
}

.gate-pic {
  &__overlay {
    fill: transparent;
    cursor: pointer;

    &_dark {
      cursor: default;
      fill: rgba(0,0,0,0.25);
    }
  }

  &__background {
    fill: #293340ff;

    &_parent {
      fill: rgb(47, 58, 71);
    }
  }

  &__header {
    fill: white;
  }

  &__label {
    @include gate-text;
    fill: #161619;
    font-size: 12px;
  }

  &__plane {
    fill: white;
  }

  &__pobt-text {
    @include gate-text;
    fill: white;
    font-size: 12px;
  }

  &__progress-circle {
    fill: white;
  }

  &__progress-text {
    @include gate-text;
    font-size: 10px;
    fill: black;
    transform-box: fill-box;
    transform-origin: center;
  }
}