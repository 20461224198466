@import "../../styles/constants";
@import "../../styles/components";

.sidebarHeader {
  background: $bk-light;
  display: flex;
  align-items: center;
  padding-left: $sidebar-padding-h;
  padding-right: 10px;
}

.bell {
  color: $icon-color;
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}

.title {
  color: white;
  flex: 1;
  font-weight: 500;
  font-size: 20px;
}

.settingsBtn {
  @include generic-button;
  border-radius: 2px;

  &:hover {
    opacity: 0.8;
  }
}

.settingsBtn + .settingsBtn {
  margin-left: 10px;
}
