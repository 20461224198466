@import "../../styles/constants";
@import "../../styles/breakpoints";

@keyframes blink {
  0%{opacity: 0.4;}
  50%{opacity: 0.8;}
  100%{opacity: 1.0;}
}

.timeline {
  $border-color :#4D4F51;
  $axis-height: 24px;

  border: 1px solid $border-color;
  border-left: none;
  border-right: none;
  overflow: hidden;
  position: relative;
  background: #161B21;

  display: grid;
  grid-template-columns: min-content 1fr;
  display: -ms-grid;
  -ms-grid-columns: min-content 1fr;

  .sound {
    grid-row: 1;
    margin-bottom: 24px;

    border: none !important;
    background: #161B21;
  }

  .col1 {
    grid-column: 1;
    -ms-grid-column: 1;
    border-right: 1px solid $border-color;
  }

  .col2 {
    grid-column: 2;
    -ms-grid-column: 2;
  }

  .col1:not(:nth-last-child(2)), .col2:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  .bk-top {
    background: #2A3339;
  }

  .col1.bk-top, .col2.bk-top {
    grid-row: 2;
    -ms-grid-row: 2;
  }

  .timeaxis {
    height: $axis-height;
    position: relative;
    cursor: pointer;

    .tick {
      position: absolute;
      top:20px;
      bottom: 0px;
      padding-left: 7px;
      line-height: 16px;
      font-size: 9px;
      height: 9999px;
      border-left: 1px dashed rgba(77, 79, 81, 0.41);
      z-index: 99;
      pointer-events: none;

      & > span {
        bottom: 100%;
        position: absolute;
        left: 0px;
        transform: translateX(-50%);
        font-family: Roboto Mono;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: -0.02em;
        color: white;
      }

      .minutes {
        opacity: 0.5;
      }
    }

    .text-measure {
      visibility: hidden;
      padding-right: 6px;
    }
  }

  .time-dashline {
    position: absolute;
    top:$axis-height + 2px;
    width: 1px;
    z-index: 100;
    height: 100%;
    border-left: 1px dashed white;


    .time-label {
      position: absolute;
      top:8px;
      left: 0px;
      background: white;
      display: inline-block;

      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding: 0px 2px;
      border-radius: 1px;
      color: black;
      white-space: nowrap;
    }
  }

  .tmp-time {
    border-left: 1px dashed orange;
    pointer-events: none;
    z-index: 99;
  }

  .group-name {
    white-space: nowrap;
    padding-top: 16px;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    background: #1E252B;
  }

  .col2.bk-top + .group-name + .pane {
    padding-top: 30px;
  }

  .pane {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
    overflow: hidden;

    .row {
      $row-height: 58px;
      $bar-height: 10px;

      height: $row-height;
      position: relative;
      width: 100%;
      display: inline-block;
      flex: none;

      label {
        white-space: nowrap;
        display: block;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        position: absolute;
        bottom: $row-height/2 + $bar-height/2 + 6px;
      }

      .detection-bar, .outage-fill, .outage-bk {
        $bar-bk: #A9B1B8;

        min-width: 1px;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba($bar-bk, 0.4);
        height: $bar-height;
        transition: height 0.2s;
      }

      &.operation-row {
        .detection-range {
          font-weight: 500;
          font-size: 12px;
          color: #828F99;
          margin-left: 10px;
        }

        .detection-bar {
          cursor: pointer;
          min-width: 3px;

          &.important {
            background: rgba(213,126,9, 0.7);
          }

          &:hover {
            height: $bar-height + 4px;

            .fill {
              background: #E0E3E5;
            }
          }

          &.low-confidence {
            opacity: 0.6;
          }

          .fill {
            height: 100%;
            background: #A9B1B8;

            &.important {
              background: rgb(213,126,9);
            }
          }
        }

        .pts-bar {
          top: $row-height/2 + $bar-height/2 + 2;
          height: 2px;
          position: absolute;
          background: #A9B1B8;
          opacity: 0.3;
        }
      }

      &.outage-row {
        .outage-bk {
          left: 0px;
        }

        .outage-fill {
          background: repeating-linear-gradient(
                          45deg,
                          #8D2E10,
                          #8D2E10 5px,
                          #BD4E2B 5px,
                          #BD4E2B 10px);
          z-index: 10;
          cursor: default;
        }
      }
    }
  }
}