@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../Modal/style";

.export-modal .modal {
  width: 430px;

  .list-item {
    i {
      margin-right: 9px;
      position: relative;
      top: 1px;
      color: #A9B1B8;
    }

    i.fa-check-square {
      color: #2F373D;
    }

    &.active {
      font-weight: 500;
      background: rgba(169, 177, 184, 0.2);
    }
  }

  .manually-title {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: black;
  }

  .modal-section-title.time-range + .list-item {
    padding-top: 18px;
  }

  .range-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    display: -ms-grid;
    -ms-grid-columns: 50% 50%;
    -ms-grid-rows: auto auto;
    padding: 11px $modal-padding-h;
    border-top: 1px solid rgba(117, 119, 121, 0.4);
    background: rgba(169, 177, 184, 0.2);
    position: relative;


    &::after {
      content: '';
      border-left: 1px solid rgba(117, 119, 121, 0.4);
      position: absolute;
      left: 50%;
      top: 0px;
      bottom: 0px;
    }

    label {
      font-weight: 500;
      font-size: 12px;
      color: #4D4F51;
      margin-bottom: 8px;
      text-transform: uppercase;
    }

    input {
      outline: none;
    }

    .start-label {
      grid-row: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .start-input {
      grid-row: 2;
      grid-column: 1;
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }

    .end-label {
      grid-row: 1;
      grid-column: 2;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      margin-left: $modal-padding-h;
    }

    .end-input {
      grid-row: 2;
      grid-column: 2;
      -ms-grid-row: 2;
      -ms-grid-column: 2;
      margin-left: $modal-padding-h;
    }
  }

  .export-btn {
    line-height: 54px;
    height: 54px;
    position: relative;
    background: #008582;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    color: white;
    border-radius: 0px 0px 4px 4px;

    .double-bounce1, .double-bounce2 {
      background: rgba(255,255,255,0.6);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}