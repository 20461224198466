@import "../../styles/constants";

.turnaround-alert {
  background: rgba(218, 151, 89, 0.2);
  padding: 10px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  color: #E18813;

  a {
    text-decoration: underline;
  }
}