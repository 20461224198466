$bbox-shadow: rgba(0, 0, 0, 0.35) 0px 8px 14px;

.bbox {
  position: absolute;
  border: 1px solid red;
  pointer-events: none;
  z-index: 100;
  user-select: none;

  &.clickable {
    pointer-events: auto;
    cursor: zoom-in;
    transition: 0.2s;

    &:hover {
      box-shadow: $bbox-shadow;
    }
  }

  span {
    position: absolute;
    color: white;
    left: -1px;
    bottom:100%;
    padding: 3px;
    font-size: 80%;
  }

  span.inside {
    bottom: auto;
    top: 0px;
  }
}

.bboxHiresContainer {
  user-select: none;
  position: absolute;
  z-index: 101;
  transition: 0.2s;
  cursor: zoom-out;
  box-shadow: $bbox-shadow;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 16px;
  }
}

.bboxHiresImage {
  width: 100%;
  height: 100%;
}

.hiresTimestamp {
  position: absolute;
  z-index: 102;
  transform: translateY(-125%);
  padding: 3px;
  margin: 2px 0;
  border-radius: 2px;
  background: rgba(53,53,55, 1);
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 500;
  color: white;
  font-size: 11px;
}