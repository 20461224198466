@import "../../styles/constants";

.logo {
  .symbol {
    fill: #43828A;
  }

  .letters {
    path {
      fill-rule:evenodd;
      fill:white;
    }
  }
}
