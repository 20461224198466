@import "../../styles/constants";

.turnaround-video {
  overflow: hidden;
  background: black;
  display: flex;
  flex-direction: column;

  &__player {
    flex: 1;
  }

  &__frame-plug {
    flex: 1;
  }

  &__processing-alert {
    color: white;
    letter-spacing: 0.51px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
    font-size: 16px;
    text-shadow: 1px 1px 2px black;

    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    z-index: 20;
    width: 100%;
  }

  &__progress {
    position: absolute;
    bottom: 100%;
    left: 0px;
    width: 100%;
    background: rgba(232, 232, 232, 0.41);
    z-index: 20;
    cursor: pointer;

    &:hover div {
      height: 10px;
    }

    div {
      height: 4px;
      transition: height 0.2s;
      background: #ED1222;
    }
  }

  &__toolbar {
    position: relative;
    background: black;
    height: $video-toolbar-height;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    z-index: 10;
  }

  &__camera {
    font-weight: 500;
    font-size: 12px;
    color: #A4A4A4;
    text-transform: uppercase;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &_active {
      color: white;
      border-bottom-color: white;
    }
  }

  &__speed-dropdown, &__time {
    margin-left: auto;
  }

  &__time {
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 12px;
    background: white;
    line-height: 20px;
    height: 20px;
    padding: 0px 4px;
    border-radius: 2px;
    color: black;
    margin-right: 5px;
  }

  &__speed-dropdown + &__time {
    margin-left: 0px;
  }

  &__play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 16px;
    color: white;

    .spinner {
      position: static;
      width: 16px;
      height: 16px;
    }
  }

  &__speed-dropdown.dropdown-btn {
    font-size: 12px !important;
    background: none !important;
    line-height: 20px !important;
    height: 20px !important;
    padding: 0px 4px !important;
    color: white;
    margin-right: 5px;

    i {
      margin-left: 4px;
    }
  }
}

.turnaround-video__speed-dropdown.dropdown-menu {
  background: white !important;

  a {
    line-height: 20px !important;
    height: 20px !important;
    padding: 0px 4px !important;
    color: black !important;
    font-size: 12px !important;
    font-family: Roboto Mono !important;
    font-weight: 500 !important;

    &:hover {
      background: rgba(0,0,0,.2)  !important;
    }

    &.active {
      border-left-color: black  !important;
    }
  }
}
