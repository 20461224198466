@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../../styles/components";

.map {
  background: $bk-mid;
  padding: 7px;
  overflow: hidden;
  outline: none;
  position: relative;

  .map-svg {
    display: block;
    height: 500%;
    width: 500%;
    -webkit-transform: translate3d(0,0,0);
  }

  .spinner-bk {
    position: absolute;
    left: 0px;
    top:0px;
    width: 100%;
    height: 100%;
    background: rgba(black,.4);
  }

  .reset-btn {
    @include generic-button;

    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    left: 35px;
    top: 28px;
    border-radius: 2px;
    width: 52px;
    height: 52px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));

    &:hover {
      color: white;
      background: rgba(0, 0, 0, 0.9);
    }
  }
}

.map-tooltip {
  $tooltip-color: rgba(0, 0, 0, 0.9) !important;

  @include btn-tooltip;

  background: $tooltip-color;
  &::after {
    border-bottom-color: $tooltip-color;
  }
}