@import "../../styles/constants";
@import "../../styles/breakpoints";

.dateTimeSection {
  display: flex;
  justify-content: space-between;
}

.date {
  width: 17rem;
}

.clock {
  width: 9rem;
}

.date, .clock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #DEDCE5;
  border-radius: 2px;
  background: white;

  span, input {
    border: none;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 1.16rem;
    background: none;
  }
}

.datePickerWrapper {
  input {
    cursor: pointer;
    width: 8rem;
  }
}

.time {
  cursor: pointer;
  width: 4rem;
}