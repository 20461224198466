$border-radius: 2px;

@mixin btn {
  background: #353E47;
  border-radius: $border-radius;
  font-family: Roboto Mono;
  height: 30px;
  font-weight: 500;
  font-size: 14px;
  padding: 0px 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

@mixin generic-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $icon-color;
  font-size: 22px;
  background: #474d52;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

@mixin dropdown-btn {
  @include btn;

  .fa-chevron-down {
    margin-left: auto;
    position: relative;
    top: 1px;
  }
}

@mixin btn-tooltip {
  font-family: Work Sans !important;
  font-size: 1rem !important;
  padding: 0.5rem !important;
}