@import "../../styles/constants";
@import "../../styles/breakpoints";

$padding: 25px;

.turnaround-status-frame {
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__header {
    font-weight: 500;
    font-size: 12px;
    height: 22px;
    display: flex;
    align-items: center;
    color: white;
    padding-left: $padding;
    padding-right: $padding;
    font-family: "Roboto Mono";
  }

  &__status {
    text-transform: uppercase;
    margin-right: 1em;
    font-family: 'Work Sans', sans-serif;
  }

  &__progress {
    vertical-align: middle;
    display: flex;
    align-items: center;
    margin-right: 2em;
  }

  &__progress-bar {
    display: inline-block;
    border: 1px solid white;
    width: 10px;
    height: 16px;
    margin-right: 2px;

    &_active {
      background: white;
    }
  }

  &__inner {
    flex: 1;
    overflow: auto;
  }
}