@import "../../styles/constants";

.support-popup {
  $padding-h:20px;
  z-index: 9999999;

  background: #445059;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 32px $padding-h;
  padding-bottom: 14px;
  margin-right: 12px;
  width: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  pointer-events: auto;

  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 70px);
    right: 26px;
    border: 8px solid transparent;
    border-top-color: #445059;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-top: 0px;
    margin-bottom: 35px;
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #A9B1B8;
    margin-bottom: 12px;
    text-transform: uppercase;
    display: block;
  }

  .stars {
    margin-bottom: 47px;

    a {
      padding-right: 11px;
      display: inline-block;

      img {
        height: 32px;
      }
    }
  }

  textarea {
    height: 120px;
    background: none;
    border: none;
    width: 100%;
    outline: none;
    color: white;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    resize: none;
    margin-bottom: 32px;
  }

  .submit {
    line-height: 52px;
    display: block;
    background: white;
    color: black;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .tour-btn, .how-to-btn {
    font-size: 16px;
    display: block;
    line-height: 60px;
    border: 1px solid #828F99;
    border-left: none;
    border-right: none;
    margin-left: -$padding-h;
    margin-right: -$padding-h;
    padding: 0px $padding-h;
    color: white;
    text-decoration: none;

    &:hover {
      background: rgba(226, 226, 226, 0.51);
    }
  }

  .tour-btn {
    border-top: none;
    margin-bottom: 24px;
  }

  .thanks {
    text-align: center;
    margin-bottom: 30px;

    i {
      font-size: 50px;
      display: block;
      font-style: normal;
      margin-bottom: 12px;
    }
    span {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .contact {
    display: block;
    font-size: 16px;
    margin-bottom: 11px;
    color: white;
    text-decoration: none;
  }
}
