@import "../../styles/constants";
@import "../../styles/breakpoints";
@import "../Modal/style";

.frame-modal {
  .modal {
    display: block !important;
  }

  .events-info {
    width: 100%;

    tr:not(:last-child):not(:first-child) {
      border-bottom: 1px solid #e2e2e2;
    }

    th,
    td {
      text-align: left;
      vertical-align: middle;
    }

    th:first-child,
    td:first-child {
      padding-left: $modal-padding-h;
      // width: 100%;
    }

    th:last-child,
    td:last-child {
      padding-right: $modal-padding-h;
    }

    th {
      padding-top: $modal-padding-section;
      padding-bottom: $modal-padding-section;
      font-weight: 600;
      font-size: 1rem;
      text-transform: uppercase;
      color: #4d4f51;
      background: #e0e0e0;
    }

    td.label {
      font-size: 16px;
      color: #161619;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    td.confidence {
      width: 80px;

      span {
        line-height: 27px;
        width: 47px;
        display: inline-block;
        text-align: center;
        background: rgba(35, 144, 82, 0.2);
        border-radius: 2px;
        font-family: Roboto Mono;
        font-weight: 500;
        font-size: 14px;
        color: #239052;

        &.low {
          background: rgba(225, 136, 19, 0.15);
          color: #d38741;
        }
      }
    }

    td.date-time {
      width: 110px;

      .date {
        font-family: Roboto Mono;
        font-weight: bold;
        font-size: 10px;
        color: #828f99;
        margin-bottom: 3px;
      }

      .time {
        font-family: Roboto Mono;
        font-weight: 500;
        font-size: 14px;
      }
    }

    td.action {
      width: 50px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .modal-section-title {
    .cameras {
      margin-left: auto;
      position: relative;

      a {
        font-weight: 600;
        font-size: 12px;
        position: relative;
        margin-left: 16px;
        cursor: pointer;
        color: #a4a4a4;
        transition: 0.2s;

        &::after {
          content: "";
          color: #4d4f51;
          position: absolute;
          bottom: -0.35rem;
          left: 0px;
          height: 2px;
          background: #4d4f51;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s;
        }

        &.active {
          color: #4d4f51;

          &::after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .toolbar {
    background: $modal-section-title-bk;
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0px $modal-padding-h;
    position: relative;
    justify-content: space-evenly;

    i {
      font-size: 20px;
    }

    .prev10 {
      margin-right: 19px;
    }

    .next {
      margin-right: 19px;
    }

    span {
      font-family: Roboto Mono;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .alert {
    padding: 16px $modal-padding-h;
    text-align: center;
    font-weight: 600;
    font-size: 16px;

    i {
      margin-right: 6px;
    }

    &.error {
      color: #bd4e2b;
      background: rgba(203, 10, 10, 0.11);
    }

    &.warning {
      color: #e18813;
      background: rgba(218, 151, 89, 0.2);
    }

    &.success {
      color: #008582;
      background: rgba(31, 28, 57, 0.21);
    }
  }
}
