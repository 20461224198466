.soundBar {
  width: 100%;
  position: relative;
  height: 100%;
}
.bk {
  position: absolute;
  top: 25px;
  bottom: 24px;
  width: 100%;
  background: rgba(43,51,55,0.2);
  border-bottom: 1px solid #4D4F51;
}

.bk2 {
  position: absolute;
  top: 25px;
  bottom: 24px;
  width: 100%;
  background: #192025;
  z-index: 10;
  border-bottom: 1px solid #4D4F51;
}

.soundBarWrapper {
  position: relative;
}

.waveformImg {
  position: absolute;
  height: 128px;
  width: 100%;
}

.overlapper {
  background: #1e252b;
  height: 100%;
  position: absolute;
  transform: translateY(-100%);
}

.apuDetection {
  position: absolute;
  height: calc(100% - 50px);
  z-index: 10;
  background: rgba(213, 126, 9, 0.3);
  transform: translateY(-100%);
  pointer-events: none;
}

.player {
  position: absolute;
  cursor: pointer;
  z-index: 150;
  transform: translate(-73px, -110px);

  padding: 7px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  // i {
  //   transform: translate(0.5px, 0);
  // }
  :global(.fa-play) {
    transform: translate(1px, 0);
  }
}