
.container {
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.header {
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right : 10px;

  justify-content: space-between;
  border-right: 1px solid #4D4F51;
  background: #1E252B;

  &.active {
    background: rgba(188, 106, 1, 1);
    border-right: none;
  }
}

.content {
  padding-left: 18px;
  padding-bottom: 18px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  background: #1E252B;
  border-bottom: 1px solid #4D4F51;
  border-right: 1px solid #4D4F51;

  &.active {
    background: rgba(212, 126, 12, 1);
    border-right: none;
  }

  p + p {
    margin-top: 10px;
  }
}

.date {
  font-size: 20px;
}