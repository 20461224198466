@import "../../styles/constants";

$hover-color: #1b1b1f;

.sidebarMenu {
  background: $bk-dark;
  padding-left: $sidebar-padding-h;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 2px;
  transition: background 0.2s;

  &.collapsed {
    padding-left: 12px;

    &:hover {
      background: $hover-color;
    }
  }

  &::after {
    position: absolute;
    z-index: 10;
    content: '';
    right: 0px;
    top:0px;
    height: 100%;
    width: 20px;
    background: linear-gradient(90deg, rgba($bk-dark,0) 0%, rgba($bk-dark,1) 100%);
  }
}

.tabWrapper {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  cursor: pointer;
  transition: background 0.2s;
  position: relative;

  &:hover {
    background: linear-gradient(90deg, rgba(22,22,25,1) 0%, rgba(31,31,34,1) 50%, rgba(22,22,25,1) 100%);
  }
}

.tab {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  color: #A9B1B8;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  white-space: nowrap;

  span:first-letter {
    text-transform: uppercase;
  }

  &.active {
    color: white;
    border-bottom-color: white;
  }
}

.alertCount {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  background: #BD4E2B;
  border-radius: 2px;
  color: white;

  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  padding: 0 4px;
  margin-left: 4px;
}

.selector {
  font-weight: normal;
  font-size: 16px;
  color: #A9B1B8;
  cursor: pointer;
}

.selectorTitle {
  user-select: none;

  &:first-letter {
    text-transform: uppercase;
  }

  margin-left: 8px;
}

.selectorPopup {
  display: flex;
  flex-direction: column;
  margin-top: -4px;
  background: #353E47;
  font-size: 16px;
}

.popupElement {
  transition: 0.2s;
  padding: 18px 12px;

  &:hover {
    background:rgba(255,255,255,0.2);
  }

  &:first-letter {
    text-transform: uppercase;
  }
}