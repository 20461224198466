.cameras {
    max-width: 35%;
    display: flex;
}

.camera {
  font-weight: 500;
  font-size: 12px;
  color: #a4a4a4;
  text-transform: uppercase;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &.active {
    color: white;
    border-bottom-color: white;
  }
}

.dropdownBtn {
  position: absolute;
  background: none !important;
  font-size: 12px !important;
  line-height: 20px !important;
  padding: 4px 4px !important;
  border-radius: 2px;
  color: white;
  margin-right: 5px;

  i {
    margin-left: 4px !important;
  }
}

.dropdownMenu {
  background: white !important;
}

.dropdownItem {
  background: white !important;
  line-height: 20px !important;
  padding: 0px 6px !important;
  color: black !important;
  font-size: 12px !important;
  font-family: Roboto Mono !important;
  font-weight: 500 !important;

  &:hover {
    background: rgba(0, 0, 0, 0.2) !important;
  }

  &:global(.active) {
    border-left-color: black !important;
  }
}
