@import "../../styles/constants";

.sidebar-footer {
  background: $bk-mid;
  padding-left: 22px;
  display: flex;
  align-items: center;

  &__logo {
    height: 20px;
    margin-right: 15px;
  }

  &__copyright {
    font-size: 10px;
    color: #8C8C8D;
  }
}