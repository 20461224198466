@import "../../styles/components";

$heading-color: #7D909D;
$padding-h: 20px;

@keyframes barberpole {
  0% {
    background-position: 100% 100%;
  }
}

@mixin barberpole-container {
  background: none;
  background-image:
          repeating-linear-gradient(
                          -45deg,
                          transparent,
                          transparent 1rem,
                          $heading-color 1rem,
                          $heading-color 2rem
          );
  background-size: 200% 200%;
  animation: barberpole 10s linear infinite;
}

.turnarounds-list {
  width: 430px;
  padding-top: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__header, &__search-menu {
    height: 40px;
    display: flex;
    align-items: center;
    background: $heading-color;
    padding: 0px $padding-h;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 1px;
  }

  &__toggle-search {
    margin-left: auto;
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  &__search-menu {
    &_loading {
      @include barberpole-container;
    }

    i {
      margin-right: 6px;
    }

    input {
      background: none;
      border: none;
      font-weight: 600;
      font-size: 14px;
      flex: 1;
      color: white;
      outline: none;

      &::placeholder {
        color: white;
        opacity: 0.5;
      }
    }
  }

  &__toggle-mode {
    opacity: .5;
    align-self: stretch;
    display: flex;
    align-items: center;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    margin-left: 16px;

    &_active {
      opacity: 1;
      border-bottom-color: white;
    }
  }

  &__scroll-container {
    overflow: auto;
    flex: 1;
  }

  &__group-header {
    display: flex;
    align-items: center;
    padding-left: $padding-h;
    height: 24px;
    background: $heading-color;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  &__skeleton {
    padding: 0px $padding-h;
    display: flex;
    align-items: center;
    height: 53px;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: white;
    text-decoration: none;
  }

  &__turnaround, &__skeleton {
    padding: 0px $padding-h;
    display: flex;
    align-items: center;
    height: 53px;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: white;
    text-decoration: none;

    &:not(:last-child) {
      border-bottom: 1px solid $heading-color;
    }
  }

  &__turnaround {
    &:hover, &_active {
      background: rgba(white,.1);
    }

    img {
      height: 20px;
      margin-right: 4px;
    }

    i {
      font-size: 4px;
      opacity: .5;
      margin: 0px 6px;
    }
  }

  &__skeleton {
    background-image: url(./skeleton.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: default;
  }

  &__flight-num {
    text-transform: uppercase;
    opacity: 0.5;

    &_active {
      opacity: 1;
    }
  }

  &__turn-time {
    margin-left: auto;
    opacity: .5;
  }

  &__loading-more-container {
    @include barberpole-container;
    opacity: .5;
    height: 20px;
  }
}

