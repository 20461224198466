@import "../../styles/variables";

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .home {
    flex: 1;
  }
}

// Prevent content bouncing in iOS
html,
body {
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}