@import "constants";
@import "reset";
@import "olark";
@import "fonts";

body,html {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  font-weight: normal;
  font-size: 12px;
  background: $bk-darkest;
  color: white;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior: none;
}

* {box-sizing: border-box;}

h1.exception {
  margin-top: 150px;
  color: white;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 1px;
}

a {
  cursor: pointer;
}