.error-message {
  text-align: center;
  padding-top: 100px;
  color: #828F99;

  &__tower-icon {
    font-size: 40px;
  }

  &__title {
    margin: 32px 0px;
    font-weight: 300;
    font-size: 32px;
  }

  &__refresh-btn {
    display: inline-block;
    background: #E2E2E2;
    border-radius: 2px;
    font-size: 14px;
    text-transform: uppercase;
    color: #4D4F51;
    width: 84px;
    line-height: 32px;
    text-align: center;

    i {
      margin-right: 6px;
    }
  }
}