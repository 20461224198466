@import "../../styles/components";

$dropdown-bk: #353E47;

.dropdown-btn {
  @include dropdown-btn;
}

.dropdown-menu {
  position: absolute;
  background: $dropdown-bk;
  white-space: nowrap;
  list-style: none;
  border-radius: 2px;
  z-index: 150;
  overflow: auto;
  font-family: Roboto Mono;
  padding: 4px 0px;

  a{
    line-height: 36px;
    padding: 0px 12px;
    display: block;
    color: white;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    border-left: 4px solid transparent;
    font-weight: 300;

    &:hover {
      background: rgba(255,255,255,0.2);
    }

    &.active {
      border-color: white;
      font-weight: 500;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .loading-bar {
    margin: 0 -20px;
  }
}